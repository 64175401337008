<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>意见反馈</p>
    </div>
    <div style="margin-top: 20px">
      <el-form :model="form" label-width="120px">
        <el-form-item label="反馈内容">
          <!-- <el-input v-model="form.content" type="textarea" /> -->
          <textarea
            name=""
            id=""
            cols="100"
            rows="10"
            placeholder="请输入反馈内容"
            v-model="form.content"
          ></textarea>
        </el-form-item>
        <el-form-item label="问题图片">
          <el-upload
            v-model:file-list="fileList"
            action="#"
            :auto-upload="false"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-change="handleChange"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="">
          <p class="cancel" @click="cancel">取消</p>
          <p class="confirm" @click="confirm">提交</p>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog v-model="dialogVisible" title="" width="408px" :show-close="false" @close="close">
      <div class="dialog-box">
        <span>反馈成功</span>
        <p>反馈信息已收到，我们会加以改进</p>
        <div class="btn" @click="close">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { feedback } from '@/api/opinion'
import { upload } from '@/api/goods'
import { ElMessage } from 'element-plus'
import axios from 'axios'
const form = reactive({
  content: '',
  image: []
})
const fileList = ref([])
const dialogVisible = ref(false)
const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
    ElMessage.error('仅支持jpg、png格式!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
const handleChange = (file) => {
  upload().then((res) => {
    if (res.code == 200) {
      let formData = new FormData()
      formData.append('key', res.data.dir + '/' + file.name)
      formData.append('OSSAccessKeyId', res.data.accessid)
      formData.append('policy', res.data.policy)
      formData.append('Signature', res.data.signature)
      formData.append('file', file.raw)
      axios({
        method: 'post',
        url: res.data.host,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        for (let i = 0; i < fileList.value.length; i++) {
          if (fileList.value[i].name === file.name) {
            fileList.value[i].name =
              // response.request.responseURL +
              res.data.dir + '/' + file.name
          }
        }
        console.log(fileList.value)
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const cancel = () => {
  form.content = ''
  fileList.value = []
}
const confirm = () => {
  if (!form.content) {
    ElMessage.warning('请输入内容')
    return
  }
  if (fileList.value.length > 0) {
    fileList.value.forEach((i) => {
      form.image.push(i.name)
    })
  }
  feedback(form).then((res) => {
    if (res.code == 200) {
      dialogVisible.value = true
    }
  })
}
const close = () => {
  form.content = ''
  fileList.value = []
  dialogVisible.value = false
}
</script>
<style lang="less" scoped>
.box {
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  height: 765px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  textarea {
    background: #f6f7fb;
    border-radius: 18px;
    border: none;
    outline: none;
    padding: 20px;
  }
  .cancel {
    width: 138px;
    height: 41px;
    background: #f4f4f4;
    border-radius: 21px;
    text-align: center;
    line-height: 41px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-right: 24px;
    cursor: pointer;
  }
  .confirm {
    width: 138px;
    height: 41px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 21px;
    text-align: center;
    line-height: 41px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
  }
}
:deep(.el-form-item__label) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
:deep(.el-dialog) {
  border-radius: 20px;
}
:deep(.el-dialog__header) {
  display: none;
}
.dialog-box {
  text-align: center;
  span {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  p {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    margin-top: 14px;
    margin-bottom: 24px;
  }
  .btn {
    width: 138px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    margin: 0 auto;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 21px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
