<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>我的购买-竞价</p>
    </div>
    <div class="tabs">
      <p
        v-for="(item, index) in tabsList"
        :key="index"
        :class="current === index ? 'active' : ''"
        @click="currentClick(index, item)"
      >
        {{ item }}
      </p>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left" @click="goPage(item)">
          <img :src="item.image[0]" alt="" />
          <div class="info">
            <div class="title">
              <p v-if="item.admin_id > 0">官方发布</p>
              <p
                v-else
                style="
                  color: #66410e;
                  background: linear-gradient(135deg, #e1ba80 0%, #f2e4b3 100%);
                "
              >
                用户发布
              </p>
              <p style="margin-left: 5px">{{ item.title }}</p>
            </div>
            <p>{{ item.params[0].name }}：{{ item.name }}-{{ item.params[0].param_value }}</p>
            <p>商品编号：{{ item.id }}</p>
          </div>
          <p class="state" v-if="item.status === 0">待支付</p>
          <p class="state" v-if="item.status === 1">待发货</p>
          <p class="state" v-if="item.status === 2">待收货</p>
          <p class="state" v-if="item.status === 3">交易成功</p>
          <p class="state" v-if="item.status === 4">退款/售后</p>
          <p class="state" v-if="item.status === 7">竞价中</p>
          <p class="state" v-if="item.status === 8">竞价失败</p>
        </div>
        <div class="middle">
          <p v-if="item.status === 7 || item.status === 8">当前价</p>
          <p v-if="item.status === 1 || item.status === 2 || item.status === 3">实付款</p>
          <p v-if="item.order_type === 4">保证金</p>
          <p v-if="item.status === 0">待支付</p>
          <p class="price" v-if="item.order_type !== 4">¥ {{ item.price / 100 }}</p>
          <p class="price" v-if="item.order_type === 4">¥ {{ item.amount / 100 }}</p>
        </div>
        <div class="right">
          <p @click="buyClick(item)" v-if="item.status === 0" class="pay">立即支付</p>
          <p @click="receive(item)" v-if="item.status === 7" class="pay">立即加价</p>
          <p @click="cancel(item.id)" v-if="item.status === 0" class="cancel">取消订单</p>
          <p
            @click="sales(item.order_id)"
            v-if="item.status === 4 || item.status === 3"
            class="cancel"
          >
            申请售后
          </p>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center" v-if="list.length > 0">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="4"
      />
    </div>
    <div class="no-data" v-else>暂无数据</div>
    <biddingModal ref="biddingVisible" :goodsInfo="goodsInfo" @clickChild="clickChild" />
    <payDialog
      :dialogVisible="payment.show"
      :orderNo="payment.orderNo"
      :price="payment.price"
      :balance="payment.balance"
      @clickChild="clickChild"
    ></payDialog>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { myPurchase, balancePay } from '@/api/goods'
import { afterSales } from '@/api/order'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import biddingModal from '../../Goods/components/bidding-modal.vue'
import payDialog from '@/components/payDialog/index.vue'
const payment = reactive({
  show: false,
  orderNo: '',
  price: 0,
  balance: 0
})
const userInfo = JSON.parse(localStorage.getItem('userInfo'))
const router = useRouter()
const tabsList = ref([
  '全部',
  '待付款',
  '待发货',
  '待收货',
  '交易成功',
  '退款/售后',
  '竞价中',
  '竞价失败'
])
const current = ref(0)
const params = reactive({
  page: 1,
  page_size: 4,
  type: 2,
  status: -1
})
const total = ref(0)
const page = reactive({
  pageNum: 4,
  pageSize: 1
})
const list = ref([])
const biddingVisible = ref(null)
const goodsInfo = ref({})
const clickChild = () => {
  payment.show = false
  getList()
}
const currentClick = (index, item) => {
  if (item === '竞价中') {
    current.value = index
    params.status = 7
  } else if (item === '竞价失败') {
    current.value = index
    params.status = 8
  } else {
    current.value = index
    params.status = index - 1
  }
  getList()
}
const getList = () => {
  myPurchase(params).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
getList()
const onChange = (val) => {
  params.page = val
  getList()
}
const cancel = (id) => {
  dialogObj.show = true
  dialogObj.orderId = id
  dialogObj.dialogType = 0
  getList()
}
const buyClick = (i) => {
  payment.show = true
  payment.orderNo = i.order_no
  payment.price = i.price
  payment.balance = userInfo.balance
}
//加价
const receive = (i) => {
  goodsInfo.value = i
  biddingVisible.value.visible = true
}
//申请售后
const sales = (id) => {
  afterSales({ id: id }).then((res) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      getList()
    }
  })
}
const goPage = (item) => {
  router.push({
    path: '/order',
    query: { orderId: item.order_id, goodsId: item.id }
  })
}
</script>
<style lang="less" scoped>
.box {
  // height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .tabs {
    display: flex;
    height: 36px;
    background: #f6f8fb;
    align-items: center;
    margin-top: 20px;
    p {
      width: 80px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #06a4f9;
    }
  }
  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f3f5f9;
      padding: 10px 12px;
      border-radius: 12px;
      margin-top: 12px;
      .left {
        width: 592px;
        display: flex;
        align-items: center;
        img {
          width: 188px;
          height: 114px;
          border-radius: 12px;
        }
        .info {
          width: 300px;
          margin-left: 12px;
          margin-right: 20px;
          .title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            p:nth-child(1) {
              display: inline-block;
              width: 62px;
              padding: 2px 0;
              text-align: center;
              font-size: 13px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
              border-radius: 5px;
            }

            p:nth-child(2) {
              //   width: 100%;
              // height: 55px;
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p:nth-child(2) {
            margin: 6px 0;
          }
        }
        .state {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #05b5fc;
        }
      }
      .middle {
        width: 115px;
        text-align: center;
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        text-align: center;
        p:nth-child(1) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #330d05;
          margin-bottom: 5px;
        }
        .price {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff401b;
        }
      }
      .right {
        width: 120px;
        text-align: center;
        .pay {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-radius: 18px;
          cursor: pointer;
          margin: 0 auto;
        }
        .cancel {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          border: 1px solid #f3f5f9;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          border-radius: 18px;
          margin: 0 auto;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
    .item:nth-child(1) {
      margin-top: 20px;
    }
  }
}
.no-data {
  text-align: center;
  position: relative;
  background: url('~@/assets/images/no-data.png') no-repeat;
  width: 765px;
  height: 765px;
  margin: 0 auto;
  color: #6099f5;
  font-size: 24px;
  padding-top: 600px;
}
</style>
