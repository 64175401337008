<template>
  <div class="collect-box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>我的鉴定</p>
    </div>
    <div class="tabs">
      <p
        v-for="(item, index) in tabsList"
        :key="index"
        :class="current === index ? 'active' : ''"
        @click="currentClick(index)"
      >
        {{ item }}
      </p>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="gopage(item)">
        <div class="item-info">
          <div class="item-info-left">
            <img :src="item.image" alt="" />
            <div class="" v-if="item.status != 0">
              <p>{{ item.game_name }}</p>
              <div class="text">
                <span class="">游戏区服:</span>
                <span>{{ item.game_name }}-</span>
                <span>{{ item.server }}</span>
              </div>
              <div class="gemmologist">
                <img :src="item.portrait" alt="" />
                <span>鉴定师: {{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="right" v-if="item.status == 2">已完成</div>
          <div class="right" v-if="item.status == 1">进行中</div>
          <div class="right" v-if="item.status == 0">待补充</div>
        </div>
        <div class="btn-box">
          <p class="service-btn" @click="serviceClick(item)">联系客服</p>
          <p class="result-btn" v-if="item.status == 2" @click="port(item.id)">查看结果</p>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center" v-if="list.length > 0">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="8"
      />
    </div>
    <div class="no-data" v-else>暂无数据</div>
  </div>
</template>
<script setup>
import { appraisal, appraisalReport } from '@/api/identify'
import { ref, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getChatService } from '@/api/service'
const router = useRouter()
const store = useStore()
const total = ref(0)
const list = ref([])
const tabsList = ref(['全部', '待补充', '进行中', '已完成'])
const current = ref(0)
let status = -1
const page = reactive({
  pageNum: 8,
  pageSize: 1
})
const serviceClick = (i) => {
  getChatService({ type: 2, game_id: i.game_id }).then((res) => {
    if (res.code == 0) {
      let to = router.resolve({ path: '/service', query: { account: res.data.id } })
      window.open(to.href, '_blank')
    }
  })
}
const getAppraisal = () => {
  appraisal({ page: page.pageSize, page_size: page.pageNum, status: status }).then((res) => {
    if (res.code == 200) {
      for (let i = 0; i < res.data.list.length; i++) {
        if (res.data.list[i].param.length > 0) {
          res.data.list[i].server = res.data.list[i].param[0].name
        }
      }
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
getAppraisal()
const onChange = (val) => {
  page.pageSize = val
  getAppraisal()
}
const currentClick = (index) => {
  current.value = index
  status = index - 1
  getAppraisal()
}
const gopage = (i) => {
  if (i.status === 0) {
    store.commit('sell/setGame', { id: i.game_id, image: i.image, name: i.game_name })
    router.push({ path: '/appraisalForm', query: { type: 'pay' } })
  }
}
const port = (id) => {
  appraisalReport({ id: id }).then((res) => {
    if (res.code == 200) {
      store.commit('report/reportInfo', res.data)
      router.push({ path: '/autInfo', query: { type: 'pay' } })
    }
  })
}
onMounted(() => {})
</script>
<style lang="less" scoped>
.collect-box {
  // height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .tabs {
    display: flex;
    height: 36px;
    background: #f6f8fb;
    align-items: center;
    margin-top: 20px;
    p {
      width: 80px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #06a4f9;
    }
  }
  .list {
    width: 922px;
    // padding: 0 26px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    .item {
      width: 400px;
      height: 162px;
      border-radius: 12px;
      border: 1px solid #f3f5f9;
      margin-top: 20px;
      padding: 20px;
      .item-info {
        display: flex;
        justify-content: space-between;
        .item-info-left {
          display: flex;
          img {
            width: 64px;
            height: 64px;
            margin-right: 12px;
          }
          p {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1a1a1a;
          }
          .text {
            margin: 5px 0;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          .gemmologist {
            img {
              width: 28px;
              height: 25px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .right {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #05b5fc;
        }
      }
      .btn-box {
        display: flex;
        justify-content: flex-end;
        .service-btn {
          width: 114px;
          height: 34px;
          border-radius: 17px;
          border: 1px solid #05b5fc;
          text-align: center;
          line-height: 34px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #05b5fc;
          cursor: pointer;
        }
        .result-btn {
          width: 114px;
          height: 34px;
          border-radius: 17px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          text-align: center;
          line-height: 34px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
.no-data {
  text-align: center;
  position: relative;
  background: url('~@/assets/images/no-data.png') no-repeat;
  width: 765px;
  height: 765px;
  margin: 0 auto;
  color: #6099f5;
  font-size: 24px;
  padding-top: 600px;
}
</style>
