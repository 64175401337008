<template>
  <div class="collect-box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>我的收藏</p>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <img :src="item.image[0]" alt="" />
          <div class="info">
            <div class="title">
              <text v-if="item.admin_id > 0">官方发布</text>
              <text
                v-else
                style="
                  color: #66410e;
                  background: linear-gradient(135deg, #e1ba80 0%, #f2e4b3 100%);
                "
              >
                用户发布
              </text>
              <text style="margin-left: 5px">{{ item.title }}</text>
            </div>
            <p>{{ item.params[0].name }}：{{ item.name }}-{{ item.params[0].param_value }}</p>
            <p>商品编号：{{ item.goods_id }}</p>
          </div>
          <p class="state" v-if="item.status === 0">待审核</p>
          <p class="state" v-if="item.status === 1">上架中</p>
          <p class="state" v-if="item.status === 2">已下架</p>
        </div>
        <div class="middle">¥ {{ item.price / 100 }}</div>
        <div class="right">
          <p @click="buyClick(item)">立即购买</p>
          <p @click="collectClick(item.id)">取消收藏</p>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center" v-if="list.length > 0">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="4"
      />
    </div>
    <div class="no-data" v-else>暂无数据</div>
  </div>
</template>
<script setup>
import { collectList, delCollect } from '@/api/goods'
import { ref, onMounted, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const total = ref(0)
const list = ref([])
const page = reactive({
  pageNum: 4,
  pageSize: 1
})
function getCollect() {
  collectList({ page: page.pageSize, page_size: page.pageNum }).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
const onChange = (val) => {
  page.pageSize = val
  getCollect()
}
function collectClick(id) {
  delCollect({ ids: [id] }).then((res) => {
    if (res.code == 200) {
      message.success(res.msg)
      getCollect()
    } else {
      message.error(res.msg)
    }
  })
}
function buyClick(e) {
  router.push({ path: '/goods', query: { id: e.goods_id, gameName: e.name, type: 'buy' } })
}
onMounted(() => {
  getCollect()
})
</script>
<style lang="less" scoped>
.collect-box {
  // height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f3f5f9;
      padding: 12px;
      border-radius: 12px;
      margin-top: 12px;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 188px;
          height: 114px;
          border-radius: 12px;
        }
        .info {
          width: 300px;
          margin-left: 12px;
          .title {
            width: 300px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            text:nth-child(1) {
              display: inline-block;
              width: 62px;
              padding: 2px 0;
              text-align: center;
              font-size: 13px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
              border-radius: 5px;
            }

            text:nth-child(2) {
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p:nth-child(2) {
            margin: 6px 0;
          }
        }
        .state {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #05b5fc;
          margin: 0 28px;
        }
      }
      .middle {
        width: 115px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff401b;
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
      }
      .right {
        width: 120px;
        text-align: center;
        p:nth-child(1) {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-radius: 18px;
          cursor: pointer;
        }
        p:nth-child(2) {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          border: 1px solid #f3f5f9;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          border-radius: 18px;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
    .item:nth-child(1) {
      margin-top: 20px;
    }
  }
}
.no-data {
  text-align: center;
  position: relative;
  background: url('~@/assets/images/no-data.png') no-repeat;
  width: 765px;
  height: 765px;
  margin: 0 auto;
  color: #6099f5;
  font-size: 24px;
  padding-top: 600px;
}
</style>
