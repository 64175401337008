<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>实名认证</p>
    </div>
    <div class="form" v-if="attStatus === 0">
      <div class="item-input">
        <p>真是姓名：</p>
        <input v-model="realname" type="text" placeholder="请填写真实姓名" />
      </div>
      <div class="item-input">
        <p>身份证号：</p>
        <input v-model="idNumber" type="text" placeholder="请填写身份证号" />
      </div>
      <p class="btn" @click="attClick">认证</p>
    </div>
    <p v-else class="tips">已通过实名认证</p>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { attUser } from '@/api/user'
import { message } from 'ant-design-vue'
let props = defineProps({
  attStatus: Number
})
const attStatus = ref(null)
const realname = ref('')
const idNumber = ref('')
function attClick() {
  if (!realname.value) {
    message.warning('请输入姓名')
    return
  }
  if (!idNumber.value) {
    message.warning('请输入身份证号')
    return
  }
  attUser({ realname: realname.value, id_number: idNumber.value }).then((res) => {
    if (res.code == 200) {
      attStatus.value = 1
      message.success(res.msg)
    } else {
      message.error(res.msg)
    }
  })
}
onMounted(() => {
  attStatus.value = props.attStatus
  console.log(attStatus.value)
})
</script>
<style lang="less" scoped>
.box {
  height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .form {
    .item-input {
      display: flex;
      align-items: center;
      margin-top: 24px;
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      input {
        width: 300px;
        height: 36px;
        background: #f6f7fb;
        border-radius: 18px;
        padding-left: 10px;
      }
    }
    .btn {
      width: 114px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 18px;
      margin-left: 83px;
      margin-top: 30px;
      cursor: pointer;
    }
  }
  .tips {
    margin-top: 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
</style>
