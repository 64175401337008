<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>账号设置</p>
    </div>
    <div>
      <el-form :model="form" label-width="120px" ref="formRef" :rules="rules">
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-change="handleChange"
            :auto-upload="false"
          >
            <img v-if="form.portrait" :src="form.portrait" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户ID">
          <p>{{ form.id }}</p>
        </el-form-item>
        <el-form-item label="手机号">
          <span>{{ form.mobile }}</span>
          <span class="replace" @click="focus(1)">更换</span>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称" />
        </el-form-item>
        <el-form-item label="联系QQ">
          <el-input v-model="form.qq" placeholder="请输入可联系的QQ" />
        </el-form-item>
        <el-form-item label="联系微信">
          <el-input v-model="form.wechat" placeholder="请输入可联系的微信" />
        </el-form-item>
        <el-form-item label="联系手机">
          <el-input
            v-model="form.contact_mobile"
            placeholder="请输入可联系的手机号"
            @focus="focus(2)"
          />
        </el-form-item>
        <el-form-item label="密码管理">
          <span class="replace" style="margin-left: 0" @click="focus(3)">设置密码</span>
        </el-form-item>
        <el-form-item>
          <!-- <el-button @click="cancel">取消</el-button> -->
          <!-- <el-button type="primary" @click="onSubmit('info')">提交</el-button> -->
          <p @click="onSubmit('info')" class="submit-btn" style="margin: 0">提交</p>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog v-model="dialog.show" :title="dialog.title" width="482px" @close="close">
      <div class="edit-phone" v-if="dialog.editPhone">
        <div class="" v-show="dialog.oldPhoneShow">
          <p class="">请输入已绑定的旧手机号</p>
          <div class="wrap">
            <el-input
              placeholder="请输入您的旧手机号"
              class="phone"
              v-model="dialog.oldPhone"
            ></el-input>
            <div class="code-box">
              <el-input
                placeholder="请输入短信验证码"
                class="code"
                v-model="dialog.oldCode"
              ></el-input>
              <p class="code-btn" @click="getCode('old')" v-if="dialog.oldCountdown === 0">
                获取验证码
              </p>
              <p class="code-btn" v-else>{{ dialog.oldCountdown }} 秒后重新发送</p>
            </div>
          </div>
        </div>
        <div class="" style="margin-top: 32px">
          <p class="">请输入新的手机号</p>
          <div class="wrap">
            <el-input
              placeholder="请输入您的新手机号"
              class="phone"
              v-model="dialog.newPhone"
            ></el-input>
            <div class="code-box">
              <el-input
                placeholder="请输入短信验证码"
                class="code"
                v-model="dialog.newCode"
              ></el-input>
              <p class="code-btn" @click="getCode('new')" v-if="dialog.newCountdown === 0">
                获取验证码
              </p>
              <p class="code-btn" v-else>{{ dialog.newCountdown }} 秒后重新发送</p>
            </div>
          </div>
        </div>
        <p class="submit-btn" @click="onSubmit('phone')">确定</p>
      </div>
      <div class="edit-pwd" v-else>
        <div class="item">
          <p>手机号</p>
          <el-input
            placeholder="请输入手机号"
            style="width: 100%"
            v-model="dialog.mobile"
          ></el-input>
        </div>
        <div class="item">
          <p>验证码</p>
          <div class="code-box">
            <el-input placeholder="请输入短信验证码" v-model="dialog.code"></el-input>
            <p class="code-btn" @click="getCode('pwd')" v-if="dialog.pwdCountdown === 0">
              获取验证码
            </p>
            <p class="code-btn" v-else>{{ dialog.pwdCountdown }} 秒后重新发送</p>
          </div>
        </div>
        <div class="item">
          <p>新密码</p>
          <el-input
            placeholder="请输入新密码"
            style="width: 100%"
            v-model="dialog.newPwd"
            type="password"
          ></el-input>
        </div>
        <div class="item">
          <p>确认密码</p>
          <el-input
            placeholder="请再次输入登录密码"
            style="width: 100%"
            v-model="dialog.confirmPwd"
            type="password"
          ></el-input>
        </div>
        <p class="submit-btn" @click="onSubmit('pwd')">确定</p>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, watch } from 'vue'
import { editInfo, sendCode, changeBind, findPwd } from '@/api/user'
import { upload } from '@/api/goods'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
const dialog = reactive({
  show: false, //弹框显示隐藏
  title: '', //弹框标题
  editPhone: true, //true显示修改手机号，否则显示修改密码
  oldPhoneShow: true, //初次设置联系手机号，隐藏旧手机输入框和验证码
  type: null, //更换那种类型手机号
  //更换绑定手机号
  oldPhone: '', //旧手机号
  oldCode: '', //旧手机号获取到得验证码
  newPhone: '', //新手机号
  newCode: '', //新手机号获取到得验证码
  //修改密码
  mobile: '', //手机号
  code: '', //验证码
  newPwd: '', //新密码
  confirmPwd: '', //确认密码
  //重新获取验证码倒计时
  oldCountdown: 0,
  oldTimer: null,
  newCountdown: 0,
  newTimer: null,
  pwdCountdown: 0,
  pwdTimer: null
})
const props = defineProps({
  info: Object
})
const emit = defineEmits(['child'])
const formRef = ref(null)
const form = ref({})
const rules = reactive({
  nickname: [
    { required: true, message: '请输入昵称', trigger: 'blur' },
    { min: 3, max: 15, message: '3-15个字符之间', trigger: 'blur' }
  ]
})
form.value = props.info
const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
    ElMessage.error('仅支持jpg、png格式!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
const avatar = ref('')
const handleChange = (file) => {
  upload().then((res) => {
    if (res.code == 200) {
      let formData = new FormData()
      formData.append('key', res.data.dir + '/' + file.name)
      formData.append('OSSAccessKeyId', res.data.accessid)
      formData.append('policy', res.data.policy)
      formData.append('Signature', res.data.signature)
      formData.append('file', file.raw)
      axios({
        method: 'post',
        url: res.data.host,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        form.value.portrait = response.request.responseURL + res.data.dir + '/' + file.name
        avatar.value = res.data.dir + '/' + file.name
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const onSubmit = (val) => {
  if (val === 'info') {
    const params = {
      nickname: form.value.nickname,
      qq: form.value.qq,
      wechat: form.value.wechat
    }
    if (avatar.value) {
      params.portrait = avatar.value
    } else {
      params.portrait = form.value.portrait
    }
    editInfo(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        emit('child')
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else if (val === 'phone') {
    if (dialog.oldPhoneShow === true) {
      if (!dialog.oldCode) {
        ElMessage.warning('请输入原手机号验证码')
        return
      }
      if (!dialog.newPhone) {
        ElMessage.warning('请输入新手机号')
        return
      }
      if (!dialog.newCode) {
        ElMessage.warning('请输入新手机号验证码')
        return
      }
    } else {
      if (!dialog.newPhone) {
        ElMessage.warning('请输入新手机号')
        return
      }
      if (!dialog.newCode) {
        ElMessage.warning('请输入新手机号验证码')
        return
      }
    }
    const params = {
      new_mobile: dialog.newPhone,
      new_mobile_code: dialog.newCode,
      type: dialog.type
    }
    if (dialog.oldCode) {
      params.code = dialog.oldCode
    }
    changeBind(params).then((res) => {
      if (res.code == 200) {
        dialog.oldPhone = ''
        dialog.oldCode = ''
        dialog.newPhone = ''
        dialog.newCode = ''
        ElMessage.success(res.msg)
        dialog.show = false
        emit('child')
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else if (val === 'pwd') {
    if (!dialog.mobile) {
      ElMessage.warning('请输入手机号码')
      return
    }
    if (!dialog.code) {
      ElMessage.warning('请输入验证码')
      return
    }
    if (!dialog.newPwd) {
      ElMessage.warning('请输入新密码')
      return
    }
    if (!dialog.confirmPwd) {
      ElMessage.warning('请输入确认密码')
      return
    }
    if (dialog.confirmPwd != dialog.newPwd) {
      ElMessage.warning('两次密码不一致，请重新输入')
      return
    }
    const params = {
      mobile: dialog.mobile,
      code: dialog.code,
      password: dialog.newPwd
    }
    findPwd(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success('密码修改成功')
        dialog.mobile = ''
        dialog.code = ''
        dialog.newPwd = ''
        dialog.confirmPwd = ''
        dialog.show = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}
const getCode = (val) => {
  if (val === 'old') {
    if (!dialog.oldPhone) {
      ElMessage.warning('请输入旧手机号')
      return
    }
    const params = {
      mobile: dialog.oldPhone,
      scene: 'BDSJH'
    }
    sendCode(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        dialog.oldCountdown = 60
        dialog.oldTimer = setInterval(() => {
          if (dialog.oldCountdown > 0) {
            dialog.oldCountdown--
          } else {
            clearInterval(dialog.oldTimer)
          }
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else if (val === 'new') {
    if (!dialog.newPhone) {
      ElMessage.warning('请输入新手机号')
      return
    }
    const params = {
      mobile: dialog.newPhone,
      scene: 'BDSJH'
    }
    sendCode(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        dialog.newCountdown = 60
        dialog.newTimer = setInterval(() => {
          if (dialog.newCountdown > 0) {
            dialog.newCountdown--
          } else {
            clearInterval(dialog.newTimer)
          }
        }, 1000)
      }
    })
  } else if (val === 'pwd') {
    if (!dialog.mobile) {
      ElMessage.warning('请输入手机号')
      return
    }
    const params = {
      mobile: dialog.mobile,
      scene: 'YZMDL'
    }
    sendCode(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        dialog.pwdCountdown = 60
        dialog.pwdTimer = setInterval(() => {
          if (dialog.pwdCountdown > 0) {
            dialog.pwdCountdown--
          } else {
            clearInterval(dialog.pwdTimer)
          }
        }, 1000)
      }
    })
  }
}
const focus = (val) => {
  if (val === 1) {
    dialog.oldPhoneShow = true
    dialog.title = '更换手机号'
    dialog.editPhone = true
    dialog.type = 1
  } else if (val === 2) {
    if (form.value.contact_mobile) {
      dialog.oldPhoneShow = true
    } else {
      dialog.oldPhoneShow = false
    }
    dialog.title = '更换手机号'
    dialog.editPhone = true
    dialog.type = 2
  } else if (val === 3) {
    dialog.title = '设置密码'
    dialog.editPhone = false
  }
  dialog.show = true
}
const close = () => {
  dialog.show = false
}
watch(
  () => props.info,
  (val) => {
    form.value = val
  }
)
</script>
<style lang="less" scoped>
.box {
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  height: 765px;
  .header {
    display: flex;
    margin-bottom: 10px;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .replace {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #00b6fa;
    margin-left: 32px;
    cursor: pointer;
  }
  :deep(.el-form-item__label) {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  :deep(.el-input) {
    width: 300px;
  }
  :deep(.el-input__wrapper) {
    width: 300px;
    height: 36px;
    background: #f6f7fb;
    border-radius: 18px;
  }
  :deep(.el-input__inner) {
    width: 300px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 64px;
  height: 64px;
  text-align: center;
  border-radius: 50%;
}
:deep(.el-dialog) {
  border-radius: 20px;
}
:deep(.el-dialog__header) {
  border-radius: 20px 20px 0 0;
  background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  padding: 22px 0;
  padding-left: 20px;
  margin-right: 0;
}
:deep(.el-dialog__title) {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
:deep(.el-dialog__close) {
  color: #ffffff;
}
.edit-phone {
  p:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #808080;
  }
  .wrap {
    margin-top: 12px;
    .phone {
      width: 100%;
    }
    .code-box {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .code-btn {
        width: 112px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
        border-radius: 26px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
.edit-pwd {
  .item {
    p:nth-child(1) {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 12px;
    }
    .code-box {
      display: flex;
      justify-content: space-between;
      .code-btn {
        width: 112px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
        border-radius: 26px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .item:not(:first-child) {
    margin-top: 20px;
  }
}
.submit-btn {
  width: 178px;
  height: 48px;
  background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  border-radius: 26px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  margin-top: 50px;
  cursor: pointer;
}
</style>
