<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>提现</p>
    </div>
    <div>
      <el-form :model="form" label-width="120px">
        <el-form-item label="提现金额">
          <el-input v-model="form.amount" placeholder="请输入金额" />
        </el-form-item>
        <el-form-item label="支付宝账号">
          <el-input v-model="form.account" placeholder="请输入账户" />
        </el-form-item>
        <el-form-item label="">
          <div class="btn" @click="submit">提现</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { withdraw } from '@/api/user'
const form = reactive({
  amount: '',
  account: ''
})
const submit = () => {
  if (!form.amount) {
    ElMessage.error('请输入提现金额')
    return
  }
  if (!form.account) {
    ElMessage.error('支付宝账号')
    return
  }
  form.amount = Number(form.amount) * 100
  withdraw(form).then((res) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      form.amount = ''
      form.account = ''
    } else {
      ElMessage.error(res.msg)
    }
  })
}
</script>
<style lang="less" scoped>
.box {
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  height: 765px;
  .header {
    display: flex;
    margin-bottom: 10px;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
}
:deep(.el-form-item__label) {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
:deep(.el-input) {
  width: 300px;
}
:deep(.el-input__wrapper) {
  width: 300px;
  height: 36px;
  background: #f6f7fb;
  border-radius: 18px;
}
:deep(.el-input__inner) {
  width: 300px;
}
.btn {
  width: 138px;
  height: 41px;
  text-align: center;
  line-height: 41px;
  background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  border-radius: 21px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
</style>
