<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>我的购买-商品</p>
    </div>
    <div class="tabs">
      <p
        v-for="(item, index) in tabsList"
        :key="index"
        :class="current === index ? 'active' : ''"
        @click="currentClick(index)"
      >
        {{ item }}
      </p>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left" @click="goPage(item)">
          <img :src="item.image[0]" alt="" />
          <div class="info">
            <div class="title">
              <text v-if="item.admin_id > 0">官方发布</text>
              <text
                v-else
                style="
                  color: #66410e;
                  background: linear-gradient(135deg, #e1ba80 0%, #f2e4b3 100%);
                "
              >
                用户发布
              </text>
              <text style="margin-left: 5px">{{ item.title }}</text>
            </div>
            <p>{{ item.params[0].name }}：{{ item.name }}-{{ item.params[0].param_value }}</p>
            <p>商品编号：{{ item.id }}</p>
          </div>
          <p class="state" v-if="item.status == 0">待支付</p>
          <p class="state" v-if="item.status == 1">待发货</p>
          <p class="state" v-if="item.status == 2">待收货</p>
          <p class="state" v-if="item.status == 3">交易成功</p>
          <p class="state" v-if="item.status == 4">退款/售后</p>
          <p class="state" v-if="item.status == 5">砍价中</p>
          <p class="state" v-if="item.status == 6">砍价失败</p>
        </div>
        <div class="middle" @click="goPage(item)">¥ {{ item.amount / 100 }}</div>
        <div class="right">
          <p @click="buyClick(item)" v-if="item.status == 0" class="pay">立即支付</p>
          <p @click="receive(item.order_id)" v-if="item.status == 2" class="pay">确认收货</p>
          <p @click="cancel(item.id)" v-if="item.status == 0" class="cancel">取消订单</p>
          <p
            @click="sales(item.order_id)"
            v-if="item.status == 4 || item.status == 3"
            class="cancel"
          >
            申请售后
          </p>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center" v-if="list.length > 0">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="4"
      />
    </div>
    <div class="no-data" v-else>暂无数据</div>
    <cancelOrder
      :type="dialogObj.dialogType"
      :dialogVisible="dialogObj.show"
      :orderId="dialogObj.orderId"
      @clickChild="clickChild"
    ></cancelOrder>
    <payDialog
      :dialogVisible="payment.show"
      :orderNo="payment.orderNo"
      :price="payment.price"
      :balance="payment.balance"
      @clickChild="clickChild"
    ></payDialog>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { myPurchase } from '@/api/goods'
import { afterSales } from '@/api/order'
import cancelOrder from './cancelOrder.vue'
import payDialog from '@/components/payDialog/index.vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const props = defineProps({
  balance: Number
})
const router = useRouter()
const dialogObj = reactive({
  show: false,
  orderId: 0,
  dialogType: 0
})
const payment = reactive({
  show: false,
  orderNo: '',
  price: 0,
  balance: 0
})
const tabsList = ref([
  '全部',
  '待付款',
  '待发货',
  '待收货',
  '交易成功',
  '退款/售后',
  '砍价中',
  '砍价失败'
])
const current = ref(0)
const params = reactive({
  page: 1,
  page_size: 4,
  type: 0,
  status: -1
})
const total = ref(0)
const page = reactive({
  pageNum: 4,
  pageSize: 1
})
const list = ref([])
const currentClick = (index) => {
  console.log(index)
  current.value = index
  params.status = index - 1
  getList()
}
const getList = () => {
  myPurchase(params).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
getList()
const onChange = (val) => {
  params.page = val
  getList()
}
const cancel = (id) => {
  dialogObj.show = true
  dialogObj.orderId = id
  dialogObj.dialogType = 0
  getList()
}
const clickChild = (e) => {
  dialogObj.show = e
  payment.show = e
  getList()
}
const buyClick = (i) => {
  payment.show = true
  payment.orderNo = i.order_no
  payment.price = i.price
  payment.balance = props.balance
}
//收货
const receive = (id) => {
  dialogObj.show = true
  dialogObj.orderId = id
  dialogObj.dialogType = 1
}
//申请售后
const sales = (id) => {
  afterSales({ id: id }).then((res) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      getList()
    }
  })
}
const goPage = (item) => {
  router.push({
    path: '/order',
    query: { orderId: item.order_id, goodsId: item.id }
  })
}
</script>
<style lang="less" scoped>
.box {
  // height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .tabs {
    display: flex;
    height: 36px;
    background: #f6f8fb;
    align-items: center;
    margin-top: 20px;
    p {
      width: 80px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #06a4f9;
    }
  }
  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f3f5f9;
      padding: 12px;
      border-radius: 12px;
      margin-top: 12px;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 188px;
          height: 114px;
          border-radius: 12px;
        }
        .info {
          width: 300px;
          margin-left: 12px;
          .title {
            width: 300px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            text:nth-child(1) {
              display: inline-block;
              width: 62px;
              padding: 2px 0;
              text-align: center;
              font-size: 13px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
              border-radius: 5px;
            }

            text:nth-child(2) {
              //   width: 100%;
              height: 55px;
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p:nth-child(2) {
            margin: 6px 0;
          }
        }
        .state {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #05b5fc;
          margin: 0 28px;
        }
      }
      .middle {
        width: 115px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff401b;
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
      }
      .right {
        width: 120px;
        text-align: center;
        z-index: 999;
        .pay {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-radius: 18px;
          cursor: pointer;
        }
        .cancel {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          border: 1px solid #f3f5f9;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          border-radius: 18px;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
    .item:nth-child(1) {
      margin-top: 20px;
    }
  }
}
.no-data {
  text-align: center;
  position: relative;
  background: url('~@/assets/images/no-data.png') no-repeat;
  width: 765px;
  height: 765px;
  margin: 0 auto;
  color: #6099f5;
  font-size: 24px;
  padding-top: 600px;
}
</style>
