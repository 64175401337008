<template>
  <div class="user-page">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>个人中心</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="menu-item" style="cursor: pointer">
          <img src="@/assets/images/user-collect.png" alt="" />
          <text :class="{ active: current === 0 }" @click="currentClick(0)">我的收藏</text>
        </div>
        <div class="menu-item">
          <img src="@/assets/images/user-purchase.png" alt="" />
          <text>我的购买</text>
          <div class="submenu">
            <p style="cursor: pointer" :class="{ active: current === 1 }" @click="currentClick(1)">
              商品
            </p>
            <p style="cursor: pointer" :class="{ active: current === 2 }" @click="currentClick(2)">
              竞价
            </p>
          </div>
        </div>
        <div class="menu-item">
          <img src="@/assets/images/user-publish.png" alt="" />
          <text>我发布的</text>
          <div class="submenu">
            <p style="cursor: pointer" :class="{ active: current === 3 }" @click="currentClick(3)">
              商品
            </p>
            <p style="cursor: pointer" :class="{ active: current === 4 }" @click="currentClick(4)">
              竞价
            </p>
          </div>
        </div>
        <div class="menu-item" style="cursor: pointer" @click="currentClick(5)">
          <img src="@/assets/images/user-appraisal.png" alt="" />
          <text :class="{ active: current === 5 }">我的鉴定</text>
        </div>
        <div class="menu-item">
          <img src="@/assets/images/user-wallet.png" alt="" />
          <text>我的钱包</text>
          <div class="submenu">
            <p style="cursor: pointer" :class="{ active: current === 6 }" @click="currentClick(6)">
              提现
            </p>
            <p style="cursor: pointer" :class="{ active: current === 7 }" @click="currentClick(7)">
              交易明细
            </p>
          </div>
        </div>
        <div class="menu-item" style="cursor: pointer" @click="currentClick(8)">
          <img src="@/assets/images/user-account.png" alt="" />
          <text :class="{ active: current === 8 }">账号设置</text>
        </div>
        <div class="menu-item">
          <img src="@/assets/images/user-serve.png" alt="" />
          <text>服务中心</text>
          <div class="submenu">
            <p style="cursor: pointer" :class="{ active: current === 9 }" @click="currentClick(9)">
              帮助中心
            </p>
            <p
              style="cursor: pointer"
              :class="{ active: current === 10 }"
              @click="currentClick(10)"
            >
              意见反馈
            </p>
            <p
              style="cursor: pointer"
              :class="{ active: current === 11 }"
              @click="currentClick(11)"
            >
              联系客服
            </p>
            <p
              style="cursor: pointer"
              :class="{ active: current === 12 }"
              @click="currentClick(12)"
            >
              实名认证
            </p>
          </div>
        </div>
        <!-- <div class="menu-item" style="cursor: pointer">
          <img src="@/assets/images/user-Logout.png" alt="" />
          <text>退出登录</text>
        </div> -->
      </div>
      <div class="right">
        <div class="right-header">
          <div class="user-info">
            <img :src="info.portrait" alt="" v-if="info.portrait" />
            <img src="@/assets/images/avatar.png" alt="" v-else />
            <div class="describe">
              <p>{{ info.nickname }}</p>
              <p>ID:{{ info.id }}</p>
              <p v-if="info.att_status === 1">已实名认证</p>
              <p v-else>未实名认证</p>
            </div>
          </div>
          <div class="withdrawal">
            <p>¥{{ info.withdraw_money / 100 }}</p>
            <p>总提现金额</p>
          </div>
          <div class="wrap">
            <div class="balance">
              <p>¥{{ info.balance / 100 }}</p>
              <p>账户余额</p>
            </div>
            <div class="btn" @click="currentClick(6)">提现</div>
          </div>
        </div>
        <collectList v-if="current === 0"></collectList>
        <myPurchase v-if="current === 1" :balance="info.balance"></myPurchase>
        <myBidding v-if="current === 2"></myBidding>
        <myGoods v-if="current === 3" />
        <publishBidding v-if="current === 4" />
        <myIdentify v-if="current === 5" />
        <withdrawal v-if="current === 6" />
        <transactionDetails v-if="current === 7"></transactionDetails>
        <account v-if="current === 8" :info="info" @child="getUserInfo" />
        <help v-if="current === 9" />
        <opinion v-if="current === 10" />
        <authentication v-if="current === 12" :attStatus="info.att_status"></authentication>
      </div>
    </div>
  </div>
</template>
<script setup>
import collectList from './components/collectList.vue'
import myPurchase from './components/myPurchase.vue'
import myBidding from './components/bidding.vue'
import myGoods from './components/myGoods.vue'
import publishBidding from './components/publishBidding.vue'
import myIdentify from './components/myIdentify.vue'
import withdrawal from './components/withdrawal.vue'
import transactionDetails from './components/transactionDetails'
import account from './components/account.vue'
import help from './components/help.vue'
import opinion from './components/opinion'
import authentication from './components/authentication.vue'
import { ref, reactive, onMounted, watch } from 'vue'
import { userInfo } from '@/api/user'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getChatService } from '@/api/service'
const store = useStore()
const route = useRoute()
const router = useRouter()
const info = ref({})
const current = ref(0)
const getUserInfo = () => {
  userInfo().then((res) => {
    if (res.code == 200) {
      info.value = res.data
    }
  })
}
getUserInfo()

const currentClick = (e) => {
  current.value = e
  if (e == 11) {
    getChatService({ type: 1 }).then((res) => {
      if (res.code == 0) {
        let to = router.resolve({ path: '/service', query: { account: res.data.id } })
        window.open(to.href, '_blank')
      }
    })
  }
}
watch(
  () => route.query.current,
  (val) => {
    current.value = Number(val)
  }
)
onMounted(() => {
  current.value = Number(route.query.current)
})
</script>
<style lang="less" scoped>
.user-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  .content {
    display: flex;
    .left {
      width: 215px;
      background: #ffffff;
      border-radius: 12px;
      padding: 24px 34px;
      .menu-item {
        // display: flex;
        // align-items: center;
        img {
          margin-right: 12px;
        }
        text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .active {
          color: #06a4f9;
        }
        .submenu {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          padding-left: 40px;
          p {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 24px;
          }
          .active {
            color: #06a4f9;
          }
        }
      }
      .menu-item:not(:first-child) {
        margin-top: 36px;
      }
    }
    .right {
      margin-left: 10px;
      .right-header {
        width: 974px;
        height: 130px;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(0, 17, 86, 0.05);
        border-radius: 12px;
        padding: 28px;
        display: flex;
        justify-content: space-between;
        .user-info {
          display: flex;
          align-items: center;
          img {
            width: 74px;
            height: 74px;
          }
          .describe {
            margin-left: 10px;
            p:nth-child(1) {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
            p:nth-child(3) {
              width: 71px;
              height: 19px;
              text-align: center;
              line-height: 19px;
              font-size: 12px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
              border-radius: 5px;
            }
          }
        }
        .withdrawal {
          text-align: center;
          margin-top: 3px;
          p:nth-child(1) {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-top: 15px;
          }
        }
        .wrap {
          display: flex;
          .balance {
            text-align: center;
            margin-top: 3px;

            p:nth-child(1) {
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #999999;
              margin-top: 15px;
            }
          }
          .btn {
            width: 121px;
            height: 42px;
            text-align: center;
            line-height: 42px;
            background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            border-radius: 20px;
            margin-left: 90px;
            margin-top: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
