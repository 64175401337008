<template>
  <div class="collect-box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>我的发布-商品</p>
    </div>
    <div class="tabs">
      <p
        v-for="(item, index) in tabsList"
        :key="index"
        :class="current === index ? 'active' : ''"
        @click="currentClick(index)"
      >
        {{ item }}
      </p>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <img :src="item.image[0]" alt="" />
          <div class="info">
            <div class="title">
              <text v-if="item.admin_id > 0">官方发布</text>
              <text
                v-else
                style="
                  color: #66410e;
                  background: linear-gradient(135deg, #e1ba80 0%, #f2e4b3 100%);
                "
              >
                用户发布
              </text>
              <text style="margin-left: 5px">{{ item.title }}</text>
            </div>
            <p>{{ item.params[0].name }}：{{ item.name }}-{{ item.params[0].param_value }}</p>
            <p>商品编号：{{ item.id }}</p>
          </div>
          <p class="state" v-if="item.status === 0">待审核</p>
          <p class="state" v-if="item.status === 1">已上架</p>
          <p class="state" v-if="item.status === 2">已下架</p>
          <p class="state" v-if="item.status === 3">已出售</p>
          <p class="state" v-if="item.status === 4">交易中</p>
          <p class="state" v-if="item.status === 5">审核失败</p>
        </div>
        <div class="middle">¥ {{ item.price }}</div>
        <div class="right">
          <!-- <p @click="crownClick(item.id)" v-if="item.status === 1" class="primary">顶一顶</p> -->
          <!-- <p
            @click="editClick(item.id)"
            v-if="item.status === 5 || item.status === 2"
            class="primary"
          >
            编辑上架
          </p> -->
          <!-- <p @click="shelfClick(item.id)" v-if="item.status === 1" class="default">下架商品</p> -->
          <!-- <p @click="delClick(item.id)" v-if="item.status === 2" class="default">删除商品</p> -->
          <p @click="serviceClick(item)" class="primary">联系客服</p>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center" v-if="list.length > 0">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="4"
      />
    </div>
    <div class="no-data" v-else>暂无数据</div>
    <el-dialog v-model="dialogObj.visible" title="" width="408px" :show-close="false">
      <div class="dialog-box">
        <p class="title">{{ dialogObj.title }}</p>
        <p class="tips">{{ dialogObj.text }}</p>
        <div class="btn">
          <p class="cancel" @click="dialogObj.visible = false" v-if="dialogObj.btnShow">取消</p>
          <p class="confirm" @click="confirmClick">确定</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { myGoods, goodsTop, offShelfGoods, delGoods } from '@/api/goods'
import { ref, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getChatService } from '@/api/service'
const router = useRouter()
const total = ref(0)
const list = ref([])
const tabsList = ref(['全部', '待审核', '已上架', '已下架', '已出售', '交易中', '审核失败'])
const current = ref(0)
let status = -1
const page = reactive({
  pageNum: 4,
  pageSize: 1
})
const dialogObj = reactive({
  visible: false,
  title: '',
  text: '',
  btnShow: false
})
const goodsId = ref(0)
function getCollect() {
  myGoods({ page: page.pageSize, page_size: page.pageNum, type: 0, status: status }).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
const onChange = (val) => {
  page.pageSize = val
  getCollect()
}
function currentClick(index) {
  current.value = index
  status = index - 1
  getCollect()
}
//顶一顶
const crownClick = (id) => {
  goodsTop({ goods_id: id }).then((res) => {
    if (res.code == 200) {
      dialogObj.visible = true
      dialogObj.title = '顶一顶'
      dialogObj.text = '您的商品已增加曝光'
      dialogObj.btnShow = false
    }
  })
}
//下架
const shelfClick = (id) => {
  goodsId.value = id
  dialogObj.visible = true
  dialogObj.title = '下架商品'
  dialogObj.text = '是否要将商品下架'
  dialogObj.btnShow = true
}
//删除
const delClick = (id) => {
  goodsId.value = id
  dialogObj.visible = true
  dialogObj.title = '删除商品'
  dialogObj.text = '商品删除后无法恢复'
  dialogObj.btnShow = true
}
const confirmClick = () => {
  if (dialogObj.title === '顶一顶') {
    getCollect()
    dialogObj.visible = false
  } else if (dialogObj.title === '下架商品') {
    offShelfGoods({ goods_id: goodsId.value }).then((res) => {
      if (res.code == 200) {
        dialogObj.visible = false
        getCollect()
      }
    })
  } else if (dialogObj.title === '删除商品') {
    delGoods({ goods_id: goodsId.value }).then((res) => {
      if (res.code == 200) {
        dialogObj.visible = false
        getCollect()
      }
    })
  }
}
//编辑
const editClick = (id) => {}
//联系客服
const serviceClick = (i) => {
  getChatService({ type: 4, game_id: i.game_id }).then((res) => {
    if (res.code == 0) {
      let to = router.resolve({ path: '/service', query: { account: res.data.id } })
      window.open(to.href, '_blank')
    }
  })
}
onMounted(() => {
  getCollect()
})
</script>
<style lang="less" scoped>
.collect-box {
  // height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .tabs {
    display: flex;
    height: 36px;
    background: #f6f8fb;
    align-items: center;
    margin-top: 20px;
    p {
      width: 80px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #06a4f9;
    }
  }
  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f3f5f9;
      padding: 12px;
      border-radius: 12px;
      margin-top: 12px;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 188px;
          height: 114px;
          border-radius: 12px;
        }
        .info {
          width: 300px;
          margin-left: 12px;
          .title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            text:nth-child(1) {
              display: inline-block;
              width: 62px;
              padding: 2px 0;
              text-align: center;
              font-size: 13px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
              border-radius: 5px;
            }

            text:nth-child(2) {
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
          }
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p:nth-child(2) {
            margin: 6px 0;
          }
        }
        .state {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #05b5fc;
          margin: 0 28px;
        }
      }
      .middle {
        width: 115px;
        height: 98px;
        text-align: center;
        line-height: 98px;
        // padding: 36px 27px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff401b;
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
      }
      .right {
        width: 120px;
        .primary {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-radius: 18px;
          cursor: pointer;
        }
        .default {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          border: 1px solid #f3f5f9;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          border-radius: 18px;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
    .item:nth-child(1) {
      margin-top: 20px;
    }
  }
}
.no-data {
  text-align: center;
  position: relative;
  background: url('~@/assets/images/no-data.png') no-repeat;
  width: 765px;
  height: 765px;
  margin: 0 auto;
  color: #6099f5;
  font-size: 24px;
  padding-top: 600px;
}
:deep(.el-dialog) {
  border-radius: 20px;
}
:deep(.el-dialog__header) {
  display: none;
}
.dialog-box {
  text-align: center;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .tips {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    margin-top: 14px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    .cancel {
      width: 138px;
      height: 41px;
      background: #f4f4f4;
      border-radius: 21px;
      text-align: center;
      line-height: 41px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-right: 24px;
      cursor: pointer;
    }
    .confirm {
      width: 138px;
      height: 41px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 21px;
      text-align: center;
      line-height: 41px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
