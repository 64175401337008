<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>帮助中心</p>
    </div>
    <div v-html="content"></div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { article } from '@/api/goods'
const content = ref('')
const getArticle = () => {
  article({ id: 5 }).then((res) => {
    if (res.code == 200) {
      content.value = res.data.pc_content
    }
  })
}
getArticle()
</script>
<style lang="less" scoped>
.box {
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  height: 765px;
  .header {
    display: flex;
    margin-bottom: 10px;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
}
</style>
