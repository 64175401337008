import request from '@/utils/request'

/**
 * @description: 获取分类数据
 * @param {*} id 分类id
 * @return {*}
 */
//获取鉴定信息
export const appraisalInfo = (data) => {
  return request('api/appraisal_info', 'get', data)
}
//提交付费鉴定
export const addAppraisal = (data) => {
  return request('api/add_appraisal', 'post', data)
}
//提交免费鉴定
export const freeAppraisal = (data) => {
  return request('api/free_appraisal', 'post', data)
}
//鉴定报告
export const appraisalReport = (data) => {
  return request('api/appraisal_report', 'get', data)
}
//我的鉴定
export const appraisal = (data) => {
  return request('api/appraisal', 'get', data)
}
