<template>
  <div class="box">
    <div class="header">
      <img src="@/assets/images/vertical-icon.png" alt="" />
      <p>交易明细</p>
    </div>
    <div class="list">
      <a-table
        :pagination="pagination"
        :columns="columns"
        :data-source="list"
        @change="handleTableChange"
      >
        <template #typeText="{ text }">
          <p style="color: #ff401b" v-if="text === '收入'">{{ text }}</p>
          <p style="color: #0cb6ff" v-else>{{ text }}</p>
          <!-- <p style="color: red" >{{ text }}</p> -->
        </template>
      </a-table>
      <!-- <div class="title">
            <p>交易名称</p>
        </div> -->
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { trade } from '@/api/user'
const page = reactive({
  pageNum: 1,
  pageSize: 10
})
const columns = [
  {
    title: '交易名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '交易性质',
    dataIndex: 'typeText',
    key: 'typeText',
    slots: {
      customRender: 'typeText'
    }
  },
  {
    title: '时间',
    dataIndex: 'time',
    key: 'time'
  },
  {
    title: '金额',
    dataIndex: 'money',
    key: 'money'
  }
]
const pagination = reactive({
  total: 0, // 总数
  pageSize: 10,
  showSizeChanger: false,
  pageSizeOptions: ['10', '20', '40', '80', '100'],
  showTotal: (total) => `共 ${total} 条` // 分页中显示总的数据
})
const list = ref([])
const getTrade = () => {
  trade({ page: page.pageNum, page_size: page.pageSize }).then((res) => {
    if (res.code == 200) {
      for (let i = 0; i < res.data.list.length; i++) {
        res.data.list[i].time = formatDate(res.data.list[i].time)
        res.data.list[i].money = res.data.list[i].money / 100
        if (res.data.list[i].type === 0) {
          res.data.list[i].name = '账号提现'
          res.data.list[i].typeText = '提现'
        } else if (res.data.list[i].type === 1) {
          res.data.list[i].name = '账号消费'
          res.data.list[i].typeText = '消费'
        } else if (res.data.list[i].type === 2) {
          res.data.list[i].name = '账号收入'
          res.data.list[i].typeText = '收入'
        }
      }
      list.value = res.data.list
      pagination.total = res.data.total
    }
  })
}
getTrade()
const formatDate = (data) => {
  let date = new Date(data * 1000)
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let y = date.getFullYear()
  let MM = date.getMonth() + 1
  MM = MM < 10 ? '0' + MM : MM //月补0
  let d = date.getDate()
  d = d < 10 ? '0' + d : d //天补0
  let h = date.getHours()
  h = h < 10 ? '0' + h : h //小时补0
  let m = date.getMinutes()
  m = m < 10 ? '0' + m : m //分钟补0
  let s = date.getSeconds()
  s = s < 10 ? '0' + s : s //秒补0
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
}
const handleTableChange = (pagination) => {
  console.log(pagination)
  page.pageNum = pagination.current
  getTrade()
}
</script>
<style lang="less" scoped>
.box {
  height: 762px;
  background: #ffffff;
  margin-top: 10px;
  padding: 26px;
  border-radius: 12px;
  .header {
    display: flex;
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 5px;
    }
  }
  .list {
    margin-top: 1.4375rem;
  }
}
</style>
