import request from '@/utils/request'
// 注册
export const register = (data) => {
  return request('api/register', 'post', data)
}
// 忘记密码
export const findPwd = (data) => {
  return request('api/findPwd', 'post', data)
}
// 登录
export const login = (data) => {
  return request('api/login', 'post', data)
}
// 个人信息
export const userInfo = () => request('api/user_info', 'GET', {})
// 发送验证码
export const sendCode = (data) => {
  return request('api/send_code', 'post', data)
}
// 实名认证
export const attUser = (data) => {
  return request('api/att_user', 'post', data)
}
// 交易明细
export const trade = (data) => {
  return request('api/trade_pc', 'get', data)
}
// 客服列表
export const service = (data) => {
  return request('api/service', 'get', data)
}
// 获取聊天客服
export const getService = (data) => {
  return request('api/get_chat_service', 'get', data)
}
// 提现申请
export const withdraw = (data) => {
  return request('api/withdraw', 'post', data)
}
// 修改个人信息
export const editInfo = (data) => {
  return request('api/edit_info', 'post', data)
}
// 换绑手机号
export const changeBind = (data) => {
  return request('api/change_bind', 'post', data)
}
